import React, { useContext, useEffect, useState } from 'react'
import { FunctionComponent } from 'react'
import { Text, View, StyleSheet, Pressable, Linking } from 'react-native'

import Colors from '../constants/Colors'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { DateConverter } from './DateConverter'
import { Spinner } from '../components/Spinner'
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'
import { CompositeNavigationProp } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import Browser from '../constants/Browser'
import { UserContext } from '../context'
import { ArticleTabParamList, RootStackParamList } from '../types'
import { ILanguage } from '../api/languages'
import decodeHtml from '../utils/HtmlDecoder'
import { LanguageSelectorComponent } from './LanguageSelectorComponent'
import {
  fetchTranslation,
  getInternalArticle,
  getPublicInternalArticle,
} from '../api/articles/articles.query'
import { IInternalArticle } from '../api/articles/articles.types'
import formatNumber from '../utils/numberConverter'
import { multiLanguageDisabled } from '../constants/Config'
import { ScrollView } from 'react-native-gesture-handler'

export interface IInternalArticleProps {
  articleId: string
  isPublicArticle: boolean
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<ArticleTabParamList, 'ArticleList'>,
    NativeStackNavigationProp<RootStackParamList, 'List'>
  > | null
  onLanguageChange: (language: ILanguage) => void | null
}

export interface ISourceNameLinkPair {
  Name: string
  StringLink: string
}

export const InternalArticleComponent: FunctionComponent<
  IInternalArticleProps
> = (props) => {
  const { articleId, navigation, isPublicArticle } = props
  const [internalArticle, setInternalArticle] = useState<IInternalArticle>(
    {} as IInternalArticle
  )

  const [isArtcleLoading, setIsArticleLoading] = useState(true)
  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = isPublicArticle
          ? await getPublicInternalArticle({ ArticleId: articleId })
          : await getInternalArticle({ ArticleId: articleId })

        setIsArticleLoading(false)
        setInternalArticle(response?.Article ?? ({} as IInternalArticle))
        setTitle(response?.Article?.Title ?? '')
      } catch (error) {
        console.error(error)
      }
    }

    fetchArticle()
  }, [articleId])

  const [modifiedTitle, setTitle] = useState(internalArticle.Title ?? '')
  const { user, setBookmarks } = useContext(UserContext)
  const index = user.BookmarkedNews.indexOf(articleId || '')
  const isBookmarked = index > -1
  const onLanguageChangeProp = props.onLanguageChange

  const onLanguageChange = async (language: ILanguage) => {
    onLanguageChangeProp(language)
    if (
      language.Code === internalArticle.TargetLanguage ||
      language.Code === ''
    ) {
      setTitle(internalArticle.Title)
      return
    }

    try {
      const { Title } = await fetchTranslation({
        ContentId: articleId,
        LanguageCode: language.Code,
      })
      setTitle(Title)
    } catch (error) {
      console.error(error)
      setTitle(internalArticle.Title)
    }
  }

  const onPress = async (url: string) => {
    try {
      return await Linking.openURL(url)
    } catch (err) {
      return console.error('An error occurred', err)
    }
  }

  return (
    <View style={styles.container}>
      <Spinner isLoading={isArtcleLoading}></Spinner>
      <View style={styles.iconsWrapper}>
        {!isPublicArticle && (
          <>
            <Pressable
              onPress={() => {
                navigation?.goBack()
              }}
              style={styles.narrowSide}
            >
              <Icon
                name={'arrow-back-ios'}
                size={18}
                color={Colors.orangeWeb}
                style={styles.icon}
              />
            </Pressable>
            <View style={styles.leftSideIcons}>
              {!multiLanguageDisabled && (
                <View style={styles.languageSelector}>
                  <LanguageSelectorComponent
                    sourceLanguage={internalArticle.SourceLanguage}
                    defaultValue={internalArticle.TargetLanguage}
                    onChange={(language) => onLanguageChange(language)}
                  />
                </View>
              )}
              <View>
                <Pressable
                  onPress={() => {
                    if (index === -1) {
                      setBookmarks(
                        [...user.BookmarkedNews, articleId ?? ''],
                        user.BookmarkedAnalyses
                      )
                    } else {
                      const tags = [...user.BookmarkedNews]
                      tags.splice(index, 1)
                      setBookmarks(tags, user.BookmarkedAnalyses)
                    }
                  }}
                >
                  <Icon
                    name={isBookmarked ? 'bookmark' : 'bookmark-border'}
                    size={24}
                    style={styles.favoriteIcon}
                  />
                </Pressable>
              </View>
            </View>
          </>
        )}
      </View>
      <ScrollView
        style={styles.contentScroll}
        contentContainerStyle={{ flexGrow: 1 }}
        showsVerticalScrollIndicator={false}
        showsHorizontalScrollIndicator={false}
      >
        <Text style={styles.contentTags}>
          {internalArticle?.ContentTag?.map((source, index) => {
            return (
              source.Value +
              (internalArticle?.ContentTag?.length - 1 === index ? '' : ', ')
            )
          })}
        </Text>
        <View style={styles.horizontalLine} />
        <View style={styles.scrollableContent}>
          <Text style={styles.title}>{modifiedTitle}</Text>
          <View>
            <View style={styles.datas}>
              <Text>
                {internalArticle.Date && DateConverter(internalArticle.Date)}
              </Text>
              {internalArticle?.SourceList?.length > 0 && (
                <Text style={styles.sourceList}>
                  {' '}
                  Forrás ({internalArticle?.SourceList?.length})
                </Text>
              )}
              {internalArticle?.ViewCount > 0 && (
                <Text style={styles.equivalent}>
                  {formatNumber(internalArticle?.ViewCount ?? 0, 1)}
                </Text>
              )}
              {internalArticle?.AdValue > 0 && (
                <Text>{internalArticle?.AdValue}</Text>
              )}
            </View>
            <div
              style={
                Browser.isIOS
                  ? { fontSize: '20px' }
                  : Browser.isMobile
                  ? { lineHeight: '1.25' }
                  : {}
              }
              dangerouslySetInnerHTML={{
                __html: decodeHtml(internalArticle?.Content ?? ''),
              }}
            ></div>
          </View>

          {internalArticle?.SourceList?.length > 0 && (
            <View style={styles.footer}>
              <Text style={styles.footerTitle}>
                Források ({internalArticle?.SourceList?.length})
              </Text>
              <View style={styles.footerSourceList}>
                {internalArticle?.SourceList?.map((source, index) => (
                  <Text style={styles.source}>
                    {internalArticle?.SourceList?.length - 1 === index
                      ? source
                      : source + ','}
                  </Text>
                ))}
              </View>
            </View>
          )}
        </View>
      </ScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  scrollableContent: {
    flexGrow: 1,
  },
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    marginLeft: 10,
    marginRight: 10,
    backgroundColor: Colors.white,
    flexGrow: 1,
    maxWidth: 700,
    paddingRight: 15,
    paddingLeft: 15,
    width: '100%',
  },
  iconsWrapper: {
    flexDirection: 'row',
    borderRadius: 5,
    padding: 5,
    marginRight: 10,
    alignSelf: 'flex-end',
    alignItems: 'center',
    width: '100%',
  },
  narrowSide: {
    minWidth: 50,
  },
  icon: {
    marginLeft: 13,
    marginRight: 6,
  },
  leftSideIcons: {
    marginLeft: 'auto',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  languageSelector: {
    marginRight: 5,
  },
  horizontalLine: {
    height: 1,
    backgroundColor: Colors.oxfordBlue + 90,
  },
  contentScroll: {
    flexGrow: 1,
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    marginRight: 'auto',
  },
  datas: {
    flexDirection: 'row',
    gap: 10,
  },
  equivalent: {
    color: 'red',
  },
  sourceList: {
    color: Colors.oxfordBlue + 90,
  },
  contentTags: {
    marginBottom: 5,
    fontWeight: '500',
  },
  source: {
    color: Colors.oxfordBlue + 90,
    fontSize: 11,
    display: 'flex',
  },
  favoriteIcon: {
    color: Colors.darkPlatinum,
  },
  footer: {
    marginTop: 'auto',
    marginBottom: 15,
    paddingBottom: Browser.browser ? 0 : 10,
  },
  footerTitle: {
    fontWeight: 'bold',
    marginBottom: 10,
  },
  footerSourceList: {
    flexDirection: 'row',
    gap: 5,
    width: '100%',
    flexWrap: 'wrap',
  },
})
