import { StatusBar } from 'expo-status-bar'
import React, { useEffect, useState } from 'react'
import { SafeAreaProvider, SafeAreaView } from 'react-native-safe-area-context'
import Toast from 'react-native-toast-message'
import AppLoading from 'expo-app-loading'
import * as Linking from 'expo-linking'

import useCachedResources from './hooks/useCachedResources'
import Navigation from './navigation'
import { register } from './workers/serviceWorkerRegistration'
import { InstallPrompt } from './components/InstallPrompt'
import InstallScreen from './screens/InstallScreen'
import JSON from './package.json'
import { UserProvider } from './context'
import {
  deviceIdPath,
  deviceNamePath,
  isProduction,
  versionPath,
} from './constants/Config'
import Browser, { BrowserType } from './constants/Browser'
import { GoogleTracker } from './constants/GoogleTracker'
import {
  ScreenSizeConsumer,
  ScreenSizeProvider,
} from './context/screen-size.context'
import { getDeviceId } from './utils'
import { UnreadProvider } from './context/unread.context'
import PublicArticleComponent from './components/PublicArticleComponent'
import { createNavigationContainerRef } from '@react-navigation/native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import RegistrationComponent from './components/RegistrationComponent'
import {
  checkTermsUrl,
  checkValidRegistrationUrl,
  extractParamFromUrl,
} from './utils/UrlHandler'
import TermsOfServiceComponent from './components/TermsOfServiceComponent'
import { StyleSheet } from 'react-native'

register()

export const navigationRef = createNavigationContainerRef()
const Stack = createNativeStackNavigator()

export default function App() {
  const isLoadingComplete = useCachedResources()
  const [isInstalled, setIsInstalled] = useState(false)
  useEffect(() => {
    const oldVersion = localStorage.getItem(versionPath)
    const newVersion = JSON.version
    if (!isInstalled || oldVersion === newVersion) return
    localStorage.setItem(versionPath, newVersion)
    Toast.show({
      type: 'info',
      text1: `Új verzió! (v${newVersion})`,
      text2: 'Az alkalmazást a háttérben frissítettük.',
      visibilityTime: 6000,
    })
  }, [isInstalled])

  useEffect(() => {
    ;(async () => {
      const deviceId = await getDeviceId()
      const id = localStorage.getItem(deviceIdPath) || deviceId
      const name = localStorage.getItem(deviceNamePath) || navigator.userAgent
      localStorage.setItem(deviceIdPath, id)
      localStorage.setItem(deviceNamePath, name)
    })()

    const media = window.matchMedia('(display-mode: standalone)')
    /** @note change this to test install screens */
    const getIsInstalled = () =>
      media.matches ||
      (Browser.browser === BrowserType.Firefox && Browser.isMobile) ||
      !isProduction
    const listener = () => setIsInstalled(getIsInstalled())
    setIsInstalled(getIsInstalled())
    media.addEventListener('change', listener)
    return () => media.removeEventListener('change', listener)
  }, [])

  useEffect(() => {
    const tracker = new GoogleTracker()
    tracker.start()
  }, [])

  const queryParams = new URLSearchParams(window?.location?.search)
  const articleId = queryParams?.get('articleId')
  if (articleId) {
    return (
      <ScreenSizeProvider>
        <SafeAreaProvider>
          <ScreenSizeConsumer>
            {({ height }) => (
              <SafeAreaView
                style={[
                  styles.safeArea,
                  {
                    maxHeight: height,
                  },
                ]}
              >
                <PublicArticleComponent
                  articleId={articleId}
                ></PublicArticleComponent>
              </SafeAreaView>
            )}
          </ScreenSizeConsumer>
        </SafeAreaProvider>
      </ScreenSizeProvider>
    )
  }

  const url = Linking.useURL()
  const isValidToken = checkValidRegistrationUrl(url, window?.location?.host)

  if (isValidToken) {
    return (
      <ScreenSizeProvider>
        <SafeAreaProvider>
          <ScreenSizeConsumer>
            {({ height }) => (
              <SafeAreaView
                style={[
                  styles.safeArea,
                  {
                    maxHeight: height,
                  },
                ]}
              >
                <RegistrationComponent url={url!}></RegistrationComponent>
              </SafeAreaView>
            )}
          </ScreenSizeConsumer>
        </SafeAreaProvider>
      </ScreenSizeProvider>
    )
  }

  const isTermsUrl = checkTermsUrl(url, window?.location?.host)

  if (isTermsUrl) {
    const token = extractParamFromUrl(url!, 't')

    if (token != null) {
      return (
        <ScreenSizeProvider>
          <SafeAreaProvider>
            <ScreenSizeConsumer>
              {({ height }) => (
                <SafeAreaView
                  style={[
                    styles.safeArea,
                    {
                      maxHeight: height,
                    },
                  ]}
                >
                  <TermsOfServiceComponent
                    token={token}
                  ></TermsOfServiceComponent>
                </SafeAreaView>
              )}
            </ScreenSizeConsumer>
          </SafeAreaProvider>
        </ScreenSizeProvider>
      )
    }
  }

  return (
    <>
      {(() => {
        if (!isLoadingComplete) {
          return <AppLoading />
        } else {
          return (
            <ScreenSizeProvider>
              <UserProvider>
                <SafeAreaProvider>
                  <UnreadProvider>
                    <InstallPrompt />
                    {isInstalled ? <Navigation /> : <InstallScreen />}
                    <StatusBar />
                  </UnreadProvider>
                </SafeAreaProvider>
              </UserProvider>
            </ScreenSizeProvider>
          )
        }
      })()}
      <Toast />
    </>
  )
}

const styles = StyleSheet.create({
  safeArea: {
    flex: 1,
  },
})
