import React, { FC } from 'react'
import { View, Text, StyleSheet, Pressable } from 'react-native'
import Colors from '../constants/Colors'
import { ContentTagsConsumer, UserConsumer } from '../context'
import { Switch } from 'react-native-switch'
import { PreferredTagsConsumer } from '../context/preferred-tags.context'
import { RootStackScreenProps } from '../types'
import { Spinner } from '../components/Spinner'
import { ScreenSizeConsumer } from '../context/screen-size.context'

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: Colors.white,
  },
  view: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  viewSplit: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.platinum,
  },
  text: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 15,
    marginHorizontal: 13,
  },
  switch: {
    marginHorizontal: 13,
  },
})

const onPress = async (
  preferredTags: string | any[],
  setPreferredTags: (tags: string[]) => Promise<void>,
  contentItem: { Value: any },
  fetchProfileUnreadMessages: () => Promise<void>
) => {
  const index = preferredTags.indexOf(contentItem.Value)
  if (index === -1) {
    await setPreferredTags([...preferredTags, contentItem.Value])
  } else {
    const tags = [...preferredTags]
    tags.splice(index, 1)
    await setPreferredTags(tags)
  }
  fetchProfileUnreadMessages()
}

export const CategoriesScreen: FC<RootStackScreenProps<'Categories'>> = () => {
  return (
    <ScreenSizeConsumer>
      {({ height }) => (
        <UserConsumer>
          {({
            user,
            setPreferredTags,
            fetchProfileUnreadMessages,
            isLoading,
          }) => (
            <PreferredTagsConsumer>
              {(tags) => {
                return (
                  <ContentTagsConsumer>
                    {({ tags: content, isLoading: tagsLoading }) => {
                      return (
                        <View
                          style={[
                            styles.containerView,
                            { height: height - 50 },
                          ]}
                        >
                          <Spinner
                            isLoading={isLoading || tagsLoading}
                          ></Spinner>
                          {content?.map((contentItem, index) => {
                            const value = tags
                              .map((tag) => tag.Value)
                              .includes(contentItem.Value)
                            return (
                              <Pressable
                                style={[
                                  styles.view,
                                  index < content.length - 1 &&
                                    styles.viewSplit,
                                ]}
                                key={contentItem.Value}
                                onPress={() =>
                                  onPress(
                                    user.PreferredTags,
                                    setPreferredTags,
                                    contentItem,
                                    fetchProfileUnreadMessages
                                  )
                                }
                              >
                                <Text style={styles.text}>
                                  {contentItem.Title}
                                </Text>
                                <Switch
                                  onValueChange={() =>
                                    onPress(
                                      user.PreferredTags,
                                      setPreferredTags,
                                      contentItem,
                                      fetchProfileUnreadMessages
                                    )
                                  }
                                  value={value}
                                  backgroundActive={Colors.orangeWeb + 30}
                                  backgroundInactive={Colors.oxfordBlue + 30}
                                  activeText=""
                                  inActiveText=""
                                  containerStyle={{
                                    marginHorizontal: 13,
                                  }}
                                  circleSize={16}
                                  barHeight={10}
                                  circleActiveColor={Colors.orangeWeb}
                                  circleInActiveColor={Colors.oxfordBlue}
                                  circleBorderWidth={0}
                                  switchWidthMultiplier={1.5}
                                  switchLeftPx={8}
                                  switchRightPx={8}
                                />
                              </Pressable>
                            )
                          })}
                        </View>
                      )
                    }}
                  </ContentTagsConsumer>
                )
              }}
            </PreferredTagsConsumer>
          )}
        </UserConsumer>
      )}
    </ScreenSizeConsumer>
  )
}
