import React, { useRef, useEffect, useCallback, useMemo, FC } from 'react'
import {
  View,
  Text,
  PanResponder,
  Animated,
  ActivityIndicator,
  findNodeHandle,
} from 'react-native'
import { AnimatedCircularProgressComponent } from './AnimatedCircularProgressComponent'

export interface IRefreshControlProps {
  colors?: Array<any>
  enabled?: boolean
  onRefresh?: CallableFunction
  progressBackgroundColor?: string
  progressViewOffset?: number
  refreshing: boolean
  size?: 'small' | 'large'
  tintColor?: any
  title?: string
  titleColor?: any
  style?: any
  children?: any
}

export const RefreshControl: FC<IRefreshControlProps> = ({
  refreshing,
  tintColor,
  colors,
  style,
  progressViewOffset,
  children,
  size,
  title,
  titleColor,
  onRefresh,
  enabled,
}) => {
  const onRefreshRef = useRef(onRefresh)
  useEffect(() => {
    onRefreshRef.current = onRefresh
  }, [onRefresh])
  const enabledRef = useRef(enabled)
  useEffect(() => {
    enabledRef.current = enabled
  }, [enabled])

  const containerRef = useRef()
  const pullPosReachedState = useRef(0)
  const pullPosReachedAnimated = useRef(new Animated.Value(0))
  const pullDownSwipeMargin = useRef(new Animated.Value(0))

  useEffect(() => {
    Animated.timing(pullDownSwipeMargin.current, {
      toValue: refreshing ? 100 : 0,
      duration: 350,
      useNativeDriver: false,
    }).start()
    if (refreshing) {
      pullPosReachedState.current = 0
      pullPosReachedAnimated.current.setValue(0)
    }
  }, [refreshing])

  const onPanResponderFinish = useCallback(() => {
    if (pullPosReachedState.current && onRefreshRef.current) {
      onRefreshRef.current()
    }
    if (!pullPosReachedState.current) {
      Animated.timing(pullDownSwipeMargin.current, {
        toValue: 0,
        duration: 350,
        useNativeDriver: false,
      }).start()
    }
  }, [])

  const panResponder = useRef(
    PanResponder.create({
      onStartShouldSetPanResponder: () => false,
      onStartShouldSetPanResponderCapture: () => false,
      onMoveShouldSetPanResponder: () => {
        if (!containerRef.current) return false
        const containerDOM = findNodeHandle(containerRef.current) as any
        if (!containerDOM) return false
        return containerDOM.children[0].scrollTop === 0
      },
      onMoveShouldSetPanResponderCapture: () => false,
      onPanResponderMove: (_, gestureState) => {
        if (enabledRef.current !== undefined && !enabledRef.current) return

        const adjustedDy =
          gestureState.dy <= 0
            ? 0
            : (gestureState.dy * 150) / (gestureState.dy + 120) // Diminishing returns function
        pullDownSwipeMargin.current.setValue(adjustedDy)
        const newValue = Math.min(adjustedDy, 100) / 100
        if (newValue !== pullPosReachedState.current) {
          pullPosReachedState.current = Math.floor(newValue)
          Animated.timing(pullPosReachedAnimated.current, {
            toValue: newValue,
            duration: 15,
            useNativeDriver: false,
          }).start()
        }
      },
      onPanResponderTerminationRequest: () => true,
      onPanResponderRelease: onPanResponderFinish,
      onPanResponderTerminate: onPanResponderFinish,
    })
  )

  const refreshIndicatorColor = useMemo(
    () => (tintColor ? tintColor : colors && colors.length ? colors[0] : null),
    [colors, tintColor]
  )
  const fill = useMemo(
    () =>
      pullPosReachedAnimated.current.interpolate({
        inputRange: [0, 1],
        outputRange: [0, 100],
      }),
    []
  )

  const containerStyle = useMemo(
    () => [
      style,
      {
        overflowY: 'hidden',
        overflow: 'hidden',
        paddingTop: progressViewOffset,
      },
    ],
    [progressViewOffset, style]
  )
  const indicatorTransformStyle = useMemo(
    () => ({
      alignSelf: 'center',
      marginTop: -80,
      height: 80,
      transform: [{ translateY: pullDownSwipeMargin.current }],
    }),
    []
  )
  // This is messing with react-native-web's internal implementation
  // Will probably break if anything changes on their end
  const AnimatedContentContainer = useMemo(
    () =>
      withAnimated((childProps: any) => (
        <children.props.children.type {...childProps} />
      )),
    []
  )
  const newContentContainerStyle = useMemo(
    () => [
      children.props.children.props.style,
      { transform: [{ translateY: pullDownSwipeMargin.current }] },
    ],
    [children.props.children.props.style]
  )
  const newChildren = React.cloneElement(
    children,
    undefined,
    <>
      <Animated.View style={indicatorTransformStyle as any}>
        {refreshing ? (
          <>
            <ActivityIndicator
              color={refreshIndicatorColor || undefined}
              size={size || undefined}
              style={{ marginVertical: 10 }}
            />
            {title && (
              <Text
                style={{ color: titleColor, textAlign: 'center', marginTop: 5 }}
              >
                {title}
              </Text>
            )}
          </>
        ) : (
          <Animated.View>
            <AnimatedCircularProgressComponent
              size={38}
              width={4}
              fill={fill as any as number}
              tintColor={refreshIndicatorColor}
              backgroundColor="#00000000"
            />
          </Animated.View>
        )}
      </Animated.View>
      <AnimatedContentContainer
        {...children.props.children.props}
        style={newContentContainerStyle}
      />
    </>
  )

  return (
    <View
      ref={containerRef as any}
      style={containerStyle}
      {...panResponder.current.panHandlers}
    >
      {newChildren}
    </View>
  )
}

function withAnimated(WrappedComponent: any) {
  const displayName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component'

  class WithAnimated extends React.Component {
    static displayName = `WithAnimated(${displayName})`

    render() {
      return <WrappedComponent {...this.props} />
    }
  }

  return Animated.createAnimatedComponent(WithAnimated)
}
