import React from 'react'
import { FunctionComponent } from 'react'
import { Text, StyleSheet, Image } from 'react-native'

import Colors from '../constants/Colors'
import { IArticle } from '../api/articles'

import Icon from 'react-native-vector-icons/MaterialIcons'
import { ArticleTypes } from './ListComponent'

export interface ITileProps {
  article: IArticle
  articleType: ArticleTypes
}

const setSource = (articleType: ArticleTypes, article: IArticle): string => {
  switch (articleType) {
    case ArticleTypes.Video:
      if (article.VideoUrl.includes('youtube.com')) return 'youtube'
      return 'video'
    case ArticleTypes.Facebook:
      return 'facebook'
    case ArticleTypes.LinkedIn:
      return 'linkedin'
    case ArticleTypes.Twitter:
      return 'twitter'
    case ArticleTypes.Instagram:
      return 'instagram'
    case ArticleTypes.TikTok:
      return 'tiktok'
    default:
      return ''
  }
}

export const ArticleSourceIconComponent: FunctionComponent<ITileProps> = (
  props
) => {
  const { article, articleType } = props
  const fileName = setSource(articleType, article)
  return (
    <Text style={styles.iconSourceContainer}>
      {fileName &&
        (fileName === 'video' ? (
          <Icon
            name={'videocam'}
            size={15}
            style={styles.icon}
            color={Colors.oxfordBlue}
          />
        ) : (
          <Image
            source={require(`../web/social-media-icons/${fileName}.svg`)}
            style={[styles.icon, styles.socialMediaIcons]}
          />
        ))}
    </Text>
  )
}

const styles = StyleSheet.create({
  svgStyle: {
    flex: 1,
    borderRadius: 4,
    marginRight: 5,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: -1,
    elevation: -1,
  },
  iconSourceContainer: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
  },
  icon: {
    marginRight: '5px',
  },
  socialMediaIcons: { width: '15px', height: '15px' },
})
