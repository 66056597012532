import { Dispatch } from 'react'
import { TagsActions } from '.'
import { ITag } from '../../api/tags'
import { fetchTags, IFetchTagsParams } from '../../api/tags/tags.query'
export const useFetchTags = (dispatch: Dispatch<TagsActions>) => {
  return async (params: IFetchTagsParams) => {
    try {
      dispatch({ type: 'TAGS_SET_LOADING', payload: true })
      const tagSet = new Set<string>()
      const response = await fetchTags(params)
      if (!response) throw Error('No payload for fetch tags')
      for (let tag of response) {
        if (tag.Value) {
          tagSet.add(tag.Value)
        }
      }
      const payload = [...tagSet.values()].map(
        (value) => response.find((tag) => tag.Value === value) as ITag
      )
      dispatch({ type: 'TAGS_LOAD_SUCCESS', payload })
      dispatch({ type: 'TAGS_SET_LOADING', payload: false })
    } catch (error) {
      console.error(error)
      dispatch({ type: 'TAGS_SET_LOADING', payload: false })
    }
  }
}
