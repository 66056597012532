import { Dispatch } from 'react'
import { ArticleActions } from './articles.actions'
import {
  fetchArticles,
  IFetchArticlesParams,
} from '../../api/articles/articles.query'

export const useFetchArticles = (dispatch: Dispatch<ArticleActions>) => {
  return async (params: IFetchArticlesParams) => {
    dispatch({ type: 'ARTICLES_SET_LOADING', payload: true })
    try {
      const payload = await fetchArticles(params)
      if (!payload) throw Error('No payload for fetch articles')
      dispatch({ type: 'ARTICLES_LOAD_SUCCESS', payload })
    } catch (error) {
      dispatch({ type: 'ARTICLES_LOAD_ERROR', error: error as Error })
    }
    dispatch({ type: 'ARTICLES_SET_LOADING', payload: false })
  }
}

export const useRefreshArticles = (dispatch: Dispatch<ArticleActions>) => {
  return async (params: IFetchArticlesParams) => {
    dispatch({ type: 'ARTICLES_SET_LOADING', payload: true })
    try {
      const payload = await fetchArticles(params)
      if (!payload) throw Error('No payload for fetch articles')
      dispatch({ type: 'ARTICLES_REFRESH_SUCCESS', payload })
    } catch (error) {
      dispatch({ type: 'ARTICLES_REFRESH_ERROR', error: error as Error })
    }
    dispatch({ type: 'ARTICLES_SET_LOADING', payload: false })
  }
}
