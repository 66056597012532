export function capitalize(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
}

export function isUndefiendOrNull(value: any): boolean {
  return value === undefined || value === null
}

export function omitEmpty<T extends Record<any, any>>(value: T): Partial<T> {
  return Object.keys(value).reduce((object, key) => {
    if (isUndefiendOrNull(value[key])) return object
    return { ...object, [key]: value[key] }
  }, {})
}

export const path = {
  join(parts: Array<string>, separator: string = '/') {
    const start = new RegExp(`^(${separator})`)
    const end = new RegExp(`(${separator}$)`)
    return parts
      .map((part) => (part || '').replace(start, '').replace(end, ''))
      .join(separator)
  },
}

export const now = (date = new Date()): Date => {
  const userTimezoneOffset = date.getTimezoneOffset() * 60000
  return new Date(date.getTime() - userTimezoneOffset)
}

export function getDeviceId(): Promise<string> {
  // Get the visitor identifier when you need it.
  return window.FingerprintJS.then((fp) => fp.get()).then((result) => {
    // This is the visitor identifier:
    const visitorId = result.visitorId
    return visitorId
  })
}

export function isValidDate(d: any) {
  return !isNaN(d) && d instanceof Date
}

export * from './debounce'
