import React, { FC, useRef } from 'react'
import { StyleSheet, TextInput, View } from 'react-native'

import { AnalysisTabParamProps } from '../types'
import { AnalysisListComponent } from '../components/AnalysisListComponent'
import { FiltersConsumer } from '../context'
import { useDebounce } from '../utils'
import Colors from '../constants/Colors'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { TouchableOpacity } from 'react-native-gesture-handler'
import { UnreadProvider } from '../context/unread.context'

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  sectionStyle: {
    flexDirection: 'row',
    height: 33,
    margin: 13,
    borderRadius: 50,
    borderColor: Colors.oxfordBlue,
    borderWidth: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  inputStyle: {
    flex: 1,
    color: Colors.oxfordBlue,
    paddingHorizontal: 10,
    outlineStyle: 'none',
  },
  icon: {
    paddingLeft: 5,
    paddingRight: 5,
  },
})
export const AnalysisSearchScreen: FC<
  AnalysisTabParamProps<'AnalysisList'>
> = ({ navigation }) => {
  const [SearchText, setSearchText] = useDebounce('', 500)
  const analysisSearchInput = useRef<any>()

  return (
    <View style={styles.view}>
      <View style={styles.sectionStyle}>
        <Icon
          name="search"
          size={24}
          color={Colors.oxfordBlue}
          style={styles.icon}
        />
        <TextInput
          style={styles.inputStyle}
          ref={analysisSearchInput}
          onChangeText={(text) => setSearchText(text)}
          placeholder="Keresés"
          placeholderTextColor={Colors.oxfordBlue + 95}
          autoCapitalize="none"
          keyboardType="default"
          returnKeyType="next"
          blurOnSubmit={false}
        />
        {!!SearchText && (
          <TouchableOpacity
            style={[{}]}
            onPress={() => {
              analysisSearchInput.current.clear()
              setSearchText('')
            }}
          >
            <Icon
              name="cancel"
              size={24}
              color={Colors.oxfordBlue}
              style={styles.icon}
            />
          </TouchableOpacity>
        )}
      </View>
      <FiltersConsumer>
        {({ ContentTag }) => {
          return (
            <AnalysisListComponent
              navigation={navigation}
              ContentTag={ContentTag}
              SearchText={SearchText}
            />
          )
        }}
      </FiltersConsumer>
    </View>
  )
}
