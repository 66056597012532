import React from 'react'
import { FunctionComponent } from 'react'
import { Text, View, StyleSheet, Pressable } from 'react-native'
import { Avatar, ListItem } from 'react-native-elements'

import Colors from '../constants/Colors'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { SentimentIcon } from './SentimentIcon'
import { UserConsumer } from '../context'
import { DateConverter } from './DateConverter'
import { IAnalysis } from '../api/analyses'
import { BottomTabNavigationProp } from '@react-navigation/bottom-tabs'
import { CompositeNavigationProp } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { AnalysisTabParamList, RootStackParamList } from '../types'
import { getImageUri } from './Tile'

export interface IAnalysisTileProps {
  setCommentingModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  setCommentedAnalysisId: React.Dispatch<React.SetStateAction<string>>
  read: (key: string) => void
  analysis: IAnalysis
  isUnread: boolean
  navigation: CompositeNavigationProp<
    BottomTabNavigationProp<AnalysisTabParamList, 'AnalysisList'>,
    NativeStackNavigationProp<RootStackParamList, 'List'>
  >
}

export const AnalysisTile: FunctionComponent<IAnalysisTileProps> = (props) => {
  const {
    analysis,
    isUnread,
    setCommentingModalVisible,
    setCommentedAnalysisId,
    read,
    navigation,
  } = props

  return (
    <UserConsumer>
      {({ user, setBookmarks }) => {
        const index = user.BookmarkedAnalyses.indexOf(analysis.AnalysisId)
        const isBookmarked = index > -1
        const borderLeftColor = isUnread ? Colors.orangeWeb : Colors.white
        return (
          <View
            style={{
              flexDirection: 'row',
              borderBottomWidth: 1,
              borderBottomColor: Colors.listBottomBlack,
            }}
          >
            <View
              style={{
                width: 5,
                backgroundColor: borderLeftColor,
                marginVertical: 3,
              }}
            />
            <ListItem style={{ flex: 1 }}>
              <Avatar
                title={''}
                source={{
                  uri: getImageUri(analysis.Thumbnail),
                }}
                placeholderStyle={styles.imagePlaceholder}
              />
              <ListItem.Content style={styles.listItemContent}>
                <ListItem.Title style={styles.listItemTitleContainer}>
                  <View style={styles.listItemTitleContainer}>
                    <Text style={styles.listItemTitle} numberOfLines={3}>
                      {analysis.Title}
                    </Text>
                    <Pressable
                      style={styles.messageIcon}
                      onPress={() => {
                        setCommentedAnalysisId(analysis.AnalysisId)
                        setCommentingModalVisible(true)
                      }}
                    >
                      <Icon
                        name={'message'}
                        size={24}
                        style={styles.commentIcon}
                      />
                    </Pressable>
                  </View>
                </ListItem.Title>
                <ListItem.Subtitle style={styles.subtitle}>
                  <View style={styles.dateSentimentContainer}>
                    <Text style={styles.date} numberOfLines={1}>
                      {DateConverter(analysis.Date)}
                    </Text>
                    <View style={styles.sentimentContainer}>
                      {!!analysis.SentimentTag ? (
                        <View style={styles.sentimentWrapper}>
                          <SentimentIcon sentimentTag={analysis.SentimentTag} />
                        </View>
                      ) : null}
                      <Pressable
                        style={styles.favoriteIconWrapper}
                        onPress={() => {
                          if (index === -1) {
                            setBookmarks(user.BookmarkedNews, [
                              ...user.BookmarkedAnalyses,
                              analysis.AnalysisId,
                            ])
                          } else {
                            const tags = [...user.BookmarkedAnalyses]
                            tags.splice(index, 1)
                            setBookmarks(user.BookmarkedNews, tags)
                          }
                        }}
                      >
                        <Icon
                          name={isBookmarked ? 'bookmark' : 'bookmark-border'}
                          size={24}
                          style={styles.favoriteIcon}
                        />
                      </Pressable>
                    </View>
                  </View>
                </ListItem.Subtitle>
              </ListItem.Content>
              <ListItem.Chevron
                size={16}
                color={Colors.oxfordBlue}
                name={'arrow-forward-ios'}
                onPress={() => {
                  read(analysis.AnalysisId)
                  navigation.navigate('PDFReader', analysis.Source)
                }}
              />
            </ListItem>
          </View>
        )
      }}
    </UserConsumer>
  )
}

const styles = StyleSheet.create({
  listItemContent: {
    borderRightColor: Colors.unselectedBlue,
    borderRightWidth: 2,
    paddingRight: 15,
  },
  listItemTitleContainer: {
    width: '100%',
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  listItemTitle: {
    display: 'flex',
    width: '85%',
    fontFamily: 'Roboto_500Medium',
    fontSize: 17,
    color: Colors.oxfordBlue,
    paddingBottom: 3,
  },
  subtitle: {
    width: '100%',
    height: '100%',
    alignContent: 'center',
  },
  dateSentimentContainer: {
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    marginTop: 12,
    maxHeight: 15,
    alignItems: 'center',
  },
  date: {
    display: 'flex',
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_300Light',
    fontSize: 11,
    width: '50%',
    margin: 0,
  },
  sentimentContainer: {
    flex: 1,
    flexDirection: 'row',
    width: '50%',
    justifyContent: 'flex-end',
    height: '100%',
    alignItems: 'center',
  },
  sentimentWrapper: { marginHorizontal: 15 },
  favoriteIconWrapper: { alignContent: 'center' },
  messageIcon: {
    alignItems: 'flex-end',
    width: '15%',
    height: 30,
  },
  commentIcon: {
    color: Colors.oxfordBlue,
    alignSelf: 'flex-end',
  },
  favoriteIcon: {
    color: Colors.oxfordBlue,
    alignSelf: 'center',
  },
  imagePlaceholder: {
    backgroundColor: 'transparent',
  },
})
