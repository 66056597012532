import { Dispatch } from 'react'
import { AnalysesActions } from './analyses.actions'
import {
  fetchAnalyses,
  IAnalysisMessageParams,
  IFetchAnalysesParams,
  message,
} from '../../api/analyses/analyses.query'
import { ITag } from '../../api/tags'

export const useFetchAnalyses = (dispatch: Dispatch<AnalysesActions>) => {
  return async (params: IFetchAnalysesParams) => {
    dispatch({ type: 'ANALYSES_SET_LOADING', payload: true })
    try {
      const payload = await fetchAnalyses(params)
      if (!payload) throw Error('No payload for fetch analyses')
      dispatch({ type: 'ANALYSES_LOAD_SUCCESS', payload })
    } catch (error) {
      dispatch({ type: 'ANALYSES_LOAD_ERROR', error: error as Error })
    }
    dispatch({ type: 'ANALYSES_SET_LOADING', payload: false })
  }
}

export const useRefreshAnalyses = (dispatch: Dispatch<AnalysesActions>) => {
  return async (params: IFetchAnalysesParams) => {
    dispatch({ type: 'ANALYSES_SET_LOADING', payload: true })
    try {
      const payload = await fetchAnalyses(params)
      if (!payload) throw Error('No payload for fetch analyses')
      dispatch({ type: 'ANALYSES_REFRESH_SUCCESS', payload })
    } catch (error) {
      dispatch({ type: 'ANALYSES_REFRESH_ERROR', error: error as Error })
    }
    dispatch({ type: 'ANALYSES_SET_LOADING', payload: false })
  }
}

export const useMessage = (dispatch: Dispatch<AnalysesActions>) => {
  return async (params: IAnalysisMessageParams) => {
    try {
      dispatch({ type: 'ANALYSES_SET_ERROR', payload: Error('') })
      await message(params)
    } catch (error: any) {
      console.error(error)
      dispatch({ type: 'ANALYSES_SET_ERROR', payload: error.message })
    }
  }
}
