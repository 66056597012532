import React, { FC, useContext } from 'react'
import { View, StyleSheet, Pressable } from 'react-native'
import Colors from '../constants/Colors'
import { UserContext } from '../context'
import { RootStackScreenProps } from '../types'
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from 'react-native-simple-radio-button'
import { Spinner } from '../components/Spinner'
import { ScreenSizeContext } from '../context/screen-size.context'
import { LanguagesContext } from '../context/languages.context'
import { defaultLanguage, ILanguage } from '../api/languages'

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: Colors.white,
  },
  view: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '0px',
  },
  viewSplit: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.platinum,
  },
  text: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 15,
    marginHorizontal: 13,
  },
  radio: {
    marginHorizontal: 13,
  },
})

const onPress = (
  value: string,
  setPreferredLanguage: (arg0: string) => void
) => {
  setPreferredLanguage(value)
}

export const PreferredLanguageScreen: FC<
  RootStackScreenProps<'PreferredLanguage'>
> = () => {
  const { height } = useContext(ScreenSizeContext)
  const { isLoading: isLanguageListLoading, languages } =
    useContext(LanguagesContext)
  const {
    user,
    setPreferredLanguage,
    isLoading: isUserLoading,
  } = useContext(UserContext)

  const isLoading = isUserLoading || isLanguageListLoading
  const radioButtonsData: { label: string; value: string }[] = [
    defaultLanguage,
    ...languages,
  ].map((language) => ({
    label: language.Name,
    value: language.Code,
  }))

  return (
    <View style={[styles.containerView, { height: height - 50 }]}>
      <Spinner isLoading={isLoading}></Spinner>
      <RadioForm formHorizontal={false} animation={true} initial={0}>
        {radioButtonsData.map((data, i) => (
          <Pressable
            style={[i < radioButtonsData.length - 1 && styles.viewSplit]}
            key={i + 'pressable'}
            onPress={() => onPress(data.value, setPreferredLanguage)}
          >
            <RadioButton
              labelHorizontal={true}
              key={i + 'group'}
              style={styles.view}
            >
              <RadioButtonLabel
                obj={data}
                index={i}
                key={i + 'button'}
                labelHorizontal={true}
                onPress={(value) => onPress(value, setPreferredLanguage)}
                labelStyle={styles.text}
              />
              <RadioButtonInput
                obj={data}
                index={i}
                key={i + 'input'}
                isSelected={
                  (user.PreferredLanguage || defaultLanguage.Code) ===
                  data.value
                }
                onPress={(value) => onPress(value, setPreferredLanguage)}
                buttonInnerColor={Colors.oxfordBlue}
                buttonOuterColor={Colors.oxfordBlue}
                buttonSize={8}
                buttonOuterSize={16}
                buttonWrapStyle={styles.radio}
              />
            </RadioButton>
          </Pressable>
        ))}
      </RadioForm>
    </View>
  )
}
