import React, { FC, useContext, useEffect, useState } from 'react'
import { StyleSheet, Text, View, Pressable } from 'react-native'
import { IArticle } from '../api/articles'
import { fetchTranslation } from '../api/articles/articles.query'
import { ILanguage } from '../api/languages'
import Colors from '../constants/Colors'
import { UserContext } from '../context'
import { LanguagesContext } from '../context/languages.context'
import { LanguageSelectorComponent } from './LanguageSelectorComponent'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { SentimentIcon } from './SentimentIcon'
import { multiLanguageDisabled } from '../constants/Config'

export interface ITileFunctionBarProps {
  article: IArticle
  onArticleTitleChange: (language: string) => void
}

export const TileFunctionBar: FC<ITileFunctionBarProps> = ({
  article,
  onArticleTitleChange: onArticleTitleChangeProp,
}) => {
  const { user, setBookmarks } = useContext(UserContext)
  const { languages } = useContext(LanguagesContext)
  const index = user.BookmarkedNews.indexOf(article.ArticleId)
  const isBookmarked = index > -1

  return (
    <View
      style={[
        styles.titleFavoriteContainer,
        {
          justifyContent: !!article.SentimentTag ? 'space-between' : 'flex-end',
        },
      ]}
    >
      {!!article.SentimentTag ? (
        <View style={styles.sentimentWrapper}>
          {' '}
          <SentimentIcon
            sentimentTag={article.SentimentTag}
            isInternalArticle={false}
          />
        </View>
      ) : null}
      <View style={[styles.iconWrapper, styles.bookMarksWrappwer]}>
        {' '}
        <Pressable
          onPress={() => {
            if (index === -1) {
              setBookmarks(
                [...user.BookmarkedNews, article.ArticleId],
                user.BookmarkedAnalyses
              )
            } else {
              const tags = [...user.BookmarkedNews]
              tags.splice(index, 1)
              setBookmarks(tags, user.BookmarkedAnalyses)
            }
          }}
        >
          <Icon
            name={isBookmarked ? 'bookmark' : 'bookmark-border'}
            size={24}
            style={styles.favoriteIcon}
          />
        </Pressable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  titleFavoriteContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    width: '100%',
    padding: 5,
  },
  iconWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  sentimentWrapper: {
    backgroundColor: Colors.oxfordBlue + 90,
    borderRadius: 5,
    padding: 5,
    height: 'fit-content',
  },
  bookMarksWrappwer: {
    backgroundColor: Colors.oxfordBlue + 90,
    borderRadius: 5,
    padding: 5,
  },
  favoriteIcon: {
    color: Colors.darkPlatinum,
  },
})
