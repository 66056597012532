import React from 'react'
import { View, StyleSheet, Text, Pressable } from 'react-native'

interface LinkTextProps {
  onPress: () => void
  linkText: string
}

const LinkText: React.FC<LinkTextProps> = ({ onPress, linkText }) => {
  return (
    <Pressable onPress={onPress}>
      <Text>
        <Text style={styles.linkText}> {linkText} </Text>
      </Text>
    </Pressable>
  )
}

const styles = StyleSheet.create({
  linkText: {
    color: 'blue',
    textDecorationLine: 'underline',
  },
})

export default LinkText
