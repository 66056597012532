import React, { FC, useContext } from 'react'
import { StyleSheet, View, Pressable } from 'react-native'
import { IArticle } from '../api/articles'
import { fetchTranslation } from '../api/articles/articles.query'
import { ILanguage } from '../api/languages'
import Colors from '../constants/Colors'
import { UserContext } from '../context'
import { LanguagesContext } from '../context/languages.context'
import { copyEnabled } from '../constants/Config'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { SentimentIcon } from './SentimentIcon'
import { ShareArticleComponent } from './ShareArticleComponent'

export interface ITileFunctionBarProps {
  article: IArticle
  onLanguageChange: (language: ILanguage) => void
  onArticleTitleChange: (language: string) => void
}

export const InternalTileFunctionBar: FC<ITileFunctionBarProps> = ({
  article,
  onLanguageChange: onLanguageChangeProp,
  onArticleTitleChange: onArticleTitleChangeProp,
}) => {
  const { user, setBookmarks } = useContext(UserContext)
  const { languages } = useContext(LanguagesContext)
  const index = user.BookmarkedNews.indexOf(article.ArticleId)
  const isBookmarked = index > -1

  const articleUrl = `${window.location.protocol}//${window.location.host}/articles?articleId=${article.ArticleId}`

  const onLanguageChange = async (language: ILanguage) => {
    onLanguageChangeProp(language)
    if (language.Code === article.TargetLanguage || language.Code === '') {
      onArticleTitleChangeProp(article.Title)
      return
    }

    try {
      const { Title } = await fetchTranslation({
        ContentId: article.ArticleId,
        LanguageCode: language.Code,
      })
      onArticleTitleChangeProp(Title)
    } catch (error) {
      console.error(error)
      onArticleTitleChangeProp(article.Title)
    }
  }

  return (
    <View style={styles.titleFavoriteContainer}>
      <View
        style={[
          styles.iconWrapper,
          !languages.length ? { paddingLeft: 15 } : {},
        ]}
      ></View>

      <View style={styles.leftButtons}>
        {article.SentimentTag ? (
          <SentimentIcon
            sentimentTag={article.SentimentTag}
            isInternalArticle={true}
          />
        ) : null}
        {copyEnabled && (
          <ShareArticleComponent
            articleUrl={articleUrl}
            articleTitle={article.Title}
          ></ShareArticleComponent>
        )}
        <View
          style={
            article.IsInternalArticle && !article.Thumbnail
              ? [styles.iconWrapper]
              : styles.iconWrapper
          }
        >
          <Pressable
            onPress={() => {
              if (index === -1) {
                setBookmarks(
                  [...user.BookmarkedNews, article.ArticleId],
                  user.BookmarkedAnalyses
                )
              } else {
                const tags = [...user.BookmarkedNews]
                tags.splice(index, 1)
                setBookmarks(tags, user.BookmarkedAnalyses)
              }
            }}
          >
            <Icon
              name={isBookmarked ? 'bookmark' : 'bookmark-border'}
              size={24}
              style={styles.favoriteIcon}
            />
          </Pressable>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  titleFavoriteContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  tileTitle: {
    fontFamily: 'Roboto_500Medium',
    color: Colors.oxfordBlue,
    fontSize: 17,
    marginBottom: 17,
    lineHeight: 20.5,
    paddingBottom: 1,
    paddingRight: 60,
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  source: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_300Light',
    fontSize: 11,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
  },
  copyButton: {
    fontSize: 15,
    fontWeight: 'bold',
  },
  copyButtonWrapper: {
    width: 20,
    justifyContent: 'center',
    height: '35px',
    alignItems: 'center',
  },
  copyIcon: {
    color: Colors.oxfordBlue,
  },
  tooltip: {
    position: 'absolute',
    color: Colors.oxfordBlue,
    zIndex: 9999,
    right: 20,
  },
  favoriteIcon: {
    color: Colors.darkPlatinum,
  },
  leftButtons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: 20,
  },
})
