import React, { useContext, useEffect, useState } from 'react'
import { View, StyleSheet } from 'react-native'

import { AnalysisTabParamProps } from '../types'
import { UserContext } from '../context'
import { useIsFocused } from '@react-navigation/native'
import { Spinner } from '../components/Spinner'
import { AnalysisListComponent } from '../components/AnalysisListComponent'
import Colors from '../constants/Colors'

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: Colors.white,
  },
})
export const AnalysisFavoritesScreen: React.FC<
  AnalysisTabParamProps<'AnalysisList'>
> = ({ navigation }) => {
  const { user, isLoading } = useContext(UserContext)
  const initialBookmarkedAnalysesState = user.BookmarkedAnalyses.length
    ? user.BookmarkedAnalyses
    : ['']
  const [AnalysesIds, setAnalysesIds] = useState<Array<string>>(
    initialBookmarkedAnalysesState
  )
  const isFocused = useIsFocused()
  useEffect(() => {
    if (!isFocused) return
    if (user.BookmarkedAnalyses.length) setAnalysesIds(user.BookmarkedAnalyses)
    else setAnalysesIds([''])
  }, [isFocused])
  return (
    <View style={styles.view}>
      <Spinner isLoading={isLoading}></Spinner>

      <AnalysisListComponent
        navigation={navigation}
        AnalysesIds={AnalysesIds}
      />
    </View>
  )
}
