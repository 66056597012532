import { decode } from 'entities'

export default function decodeHtml(htmlCode: string) {
  const decodedHtml = decode(htmlCode)
  return decodedHtml.replace(/ /g, ' ')

  // const container = document.createElement('div')
  // container.innerHTML = decodedHtml

  // const iframes = container.getElementsByTagName('iframe')

  // for (const iframe of Array.from(iframes)) {
  //   if (iframe.hasAttribute('frameborder')) {
  //     iframe.setAttribute('frameBorder', iframe.getAttribute('frameborder')!)
  //     iframe.removeAttribute('frameborder')
  //   }
  //   if (iframe.hasAttribute('referrerpolicy')) {
  //     iframe.setAttribute(
  //       'referrerPolicy',
  //       iframe.getAttribute('referrerpolicy')!
  //     )
  //     iframe.removeAttribute('referrerpolicy')
  //   }
  //   if (iframe.hasAttribute('allowfullscreen')) {
  //     iframe.setAttribute('allowFullScreen', 'true')
  //     iframe.removeAttribute('allowfullscreen')
  //   }

  //   const parent = iframe.parentElement
  //   if (
  //     parent &&
  //     parent.tagName.toLowerCase() === 'div' &&
  //     parent.classList.contains('embeditem')
  //   ) {
  //     parent.replaceWith(iframe)
  //   }
  // }

  // return container.innerHTML.replace(/ /g, ' ')
}
