// import './articles/articles.mock'
// import './tags/tags.mock'
// import './user/user.mock'
// import './analyses/analyses.mock'
// import './languages/languages.mock'
import { mockAdapter, client } from './client'

mockAdapter.onAny().passThrough()

export { client }
