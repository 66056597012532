import React, { FunctionComponent } from 'react'
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs'

import { AnalysisTopTabNavigator } from './AnalysisTopTabNavigator'
import { AnalysisFavoritesScreen } from '../screens/AnalysisFavoritesScreen'

import { BottomNavigationBar } from '../components/BottomNavigationBar'

import { AnalysisRootTabParamList } from '../types'
import { AnalysisListScreen } from '../screens/AnalysisListScreen'
import { AnalysisSearchScreen } from '../screens/AnalysisSearchScreen'
import { HeaderComponent } from '../components/HeaderComponent'

export interface IRootNavigatorProps {}
const BottomTab = createBottomTabNavigator<AnalysisRootTabParamList>()

export const AnalysisRootNavigator: FunctionComponent<
  IRootNavigatorProps
> = () => {
  return (
    <BottomTab.Navigator
      tabBar={(props) => <BottomNavigationBar {...props} />}
      screenOptions={{
        header: (props) => <HeaderComponent {...props} />,
      }}
    >
      <BottomTab.Screen
        name="AnalysisRoot"
        initialParams={{}}
        options={{ unmountOnBlur: true }}
      >
        {(props) => (
          <AnalysisTopTabNavigator
            navigation={props.navigation}
            route={props.route as any}
          >
            <AnalysisListScreen
              navigation={props.navigation}
              route={props.route as any}
            />
          </AnalysisTopTabNavigator>
        )}
      </BottomTab.Screen>
      <BottomTab.Screen
        name="AnalysisSearch"
        initialParams={{}}
        options={{ unmountOnBlur: true }}
      >
        {(props) => (
          <AnalysisTopTabNavigator
            navigation={props.navigation}
            route={props.route as any}
          >
            <AnalysisSearchScreen
              navigation={props.navigation}
              route={props.route as any}
            />
          </AnalysisTopTabNavigator>
        )}
      </BottomTab.Screen>
      <BottomTab.Screen
        name="AnalysisFavorites"
        component={AnalysisFavoritesScreen}
      />
    </BottomTab.Navigator>
  )
}
