import React, { FunctionComponent, useContext, useEffect } from 'react'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import Colors from '../constants/Colors'
import { ArticleTabParamList, ArticleTabParamProps } from '../types'
import { FiltersProvider } from '../context'
import { TopTabNavigationBar } from '../components/TopTabNavigationBar'
import { PreferredTagsConsumer } from '../context/preferred-tags.context'
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/lib/typescript/src/types'
import { ScrollToTopProvider } from '../context/scroll-to-top.context'

const TopTab = createMaterialTopTabNavigator<ArticleTabParamList>()

export const TopTabNavigator: FunctionComponent<
  ArticleTabParamProps<'ArticleList'>
> = ({ route, children }) => {
  const { SourceTag, ScrollToTop } = route.params

  return (
    <ScrollToTopProvider ScrollToTop={ScrollToTop}>
      <FiltersProvider SourceTag={SourceTag}>
        <PreferredTagsConsumer>
          {(tags) => {
            const filteredTags = tags.filter((tag) => tag.HasArticles)
            return (
              <TopTab.Navigator
                tabBar={(
                  props: JSX.IntrinsicAttributes & MaterialTopTabBarProps
                ) => <TopTabNavigationBar {...props} />}
                screenOptions={{
                  tabBarActiveTintColor: Colors.oxfordBlue,
                  tabBarScrollEnabled: true,
                }}
              >
                <TopTab.Screen
                  name="ArticleList"
                  initialParams={{ SourceTag } as any}
                  options={{
                    title: 'Minden Téma',
                  }}
                >
                  {() => (
                    <FiltersProvider SourceTag={SourceTag}>
                      {children as any}
                    </FiltersProvider>
                  )}
                </TopTab.Screen>
                {filteredTags.map((tag) => (
                  <TopTab.Screen
                    key={tag.Value}
                    name={`${tag.Value}List` as any}
                    options={{
                      title: tag.Title,
                    }}
                  >
                    {() => (
                      <FiltersProvider
                        SourceTag={SourceTag}
                        ContentTag={tag.Value}
                      >
                        {children as any}
                      </FiltersProvider>
                    )}
                  </TopTab.Screen>
                ))}
              </TopTab.Navigator>
            )
          }}
        </PreferredTagsConsumer>
      </FiltersProvider>
    </ScrollToTopProvider>
  )
}
