import React from 'react'
import { StyleProp, View, ViewStyle } from 'react-native'
import { Svg, Path, G } from 'react-native-svg'

/**
 * Based on the solution found at
 * https://github.com/bartgryszko/react-native-circular-progress
 */

export interface ICircularProgressComponentRequiredProps {
  size: number
  fill: number
  width: number
}

export interface ICircularProgressComponentOptionalProps {
  style?: StyleProp<ViewStyle>
  backgroundWidth?: number
  tintColor?: string
  tintTransparency?: boolean
  backgroundColor?: string
  rotation?: number
  lineCap?: string
  fillLineCap?: string
  arcSweepAngle?: number
  childrenContainerStyle?: object
  padding?: number
  renderCap?: (coords: { center: { x: number; y: number } }) => void
  dashedBackground?: { width: number; gap: number }
  dashedTint?: { width: number; gap: number }
}

export class CircularProgressComponent extends React.PureComponent<
  ICircularProgressComponentRequiredProps &
    Partial<ICircularProgressComponentOptionalProps>
> {
  polarToCartesian(
    centerX: number,
    centerY: number,
    radius: number,
    angleInDegrees: number
  ) {
    var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0
    return {
      x: centerX + radius * Math.cos(angleInRadians),
      y: centerY + radius * Math.sin(angleInRadians),
    }
  }

  circlePath(
    x: number,
    y: number,
    radius: number,
    startAngle: number,
    endAngle: number
  ) {
    var start = this.polarToCartesian(x, y, radius, endAngle * 0.9999)
    var end = this.polarToCartesian(x, y, radius, startAngle)
    var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'
    var d = [
      'M',
      start.x,
      start.y,
      'A',
      radius,
      radius,
      0,
      largeArcFlag,
      0,
      end.x,
      end.y,
    ]
    return d.join(' ')
  }

  clampFill = (fill: number) => Math.min(100, Math.max(0, fill))

  render() {
    const {
      size,
      width,
      backgroundWidth,
      tintColor = 'black',
      tintTransparency = true,
      backgroundColor,
      style,
      rotation = 90,
      lineCap = 'butt',
      fillLineCap = lineCap,
      arcSweepAngle = 360,
      fill,
      children,
      childrenContainerStyle,
      padding = 0,
      renderCap,
      dashedBackground = { width: 0, gap: 0 },
      dashedTint = { width: 0, gap: 0 },
    } = this.props

    const maxWidthCircle = backgroundWidth
      ? Math.max(width, backgroundWidth)
      : width
    const sizeWithPadding = (size as number) / 2 + padding / 2
    const radius = (size as number) / 2 - maxWidthCircle / 2 - padding / 2

    const currentFillAngle = (arcSweepAngle * this.clampFill(fill)) / 100
    const backgroundPath = this.circlePath(
      sizeWithPadding,
      sizeWithPadding,
      radius,
      tintTransparency ? 0 : currentFillAngle,
      arcSweepAngle
    )
    const circlePath = this.circlePath(
      sizeWithPadding,
      sizeWithPadding,
      radius,
      0,
      currentFillAngle
    )
    const coordinate = this.polarToCartesian(
      sizeWithPadding,
      sizeWithPadding,
      radius,
      currentFillAngle
    )
    const cap = this.props.renderCap
      ? this.props.renderCap({ center: coordinate })
      : null

    const offset = (size as number) - maxWidthCircle * 2

    const localChildrenContainerStyle = {
      ...{
        position: 'absolute',
        left: maxWidthCircle + padding / 2,
        top: maxWidthCircle + padding / 2,
        width: offset,
        height: offset,
        borderRadius: offset / 2,
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden',
      },
      ...childrenContainerStyle,
    }

    const strokeDasharrayTint =
      dashedTint.gap > 0
        ? Object.values(dashedTint).map((value) => parseInt(value as any))
        : null

    const strokeDasharrayBackground =
      dashedBackground.gap > 0
        ? Object.values(dashedBackground).map((value) => parseInt(value as any))
        : null

    return (
      <View style={style}>
        <Svg width={(size as number) + padding} height={size + padding}>
          <G
            rotation={rotation}
            originX={(size + padding) / 2}
            originY={(size + padding) / 2}
          >
            {backgroundColor && (
              <Path
                d={backgroundPath}
                stroke={backgroundColor}
                strokeWidth={backgroundWidth || width}
                strokeLinecap={lineCap as any}
                strokeDasharray={strokeDasharrayBackground as any}
                fill="transparent"
              />
            )}
            {fill > 0 && (
              <Path
                d={circlePath}
                stroke={tintColor}
                strokeWidth={width}
                strokeLinecap={fillLineCap as any}
                strokeDasharray={strokeDasharrayTint as any}
                fill="transparent"
              />
            )}
            {cap}
          </G>
        </Svg>
        {children && (
          <View style={localChildrenContainerStyle as any}>
            {(children as any)(fill)}
          </View>
        )}
      </View>
    )
  }
}
