import React, { useEffect, useState } from 'react'
import { StyleSheet, View, Text, TouchableOpacity, Modal } from 'react-native'
import { Image } from 'react-native-elements'
import Colors from '../constants/Colors'
import { isMirror } from '../constants/Config'
import originalSrc from '../variants/original/icon.png'
import mirrorSrc from '../variants/mirror/icon.png'
import bannerSrc from '../web/language_feature.png'
import { languageFeatureModalPath } from '../constants/Config'

const imgSrc = isMirror ? mirrorSrc : originalSrc

export default function NewLanguageFeatureModal({}) {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const hidden = localStorage.getItem(languageFeatureModalPath)
    if (!hidden) setIsVisible(true)
  }, [])

  return (
    <Modal
      animationType="fade"
      transparent={true}
      visible={isVisible}
      onRequestClose={() => {
        setIsVisible(false)
        localStorage.setItem(languageFeatureModalPath, 'hidden')
      }}
    >
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <View style={styles.head}>
            <Image
              placeholderStyle={styles.imagePlaceholder}
              containerStyle={styles.imageContainer}
              style={styles.image}
              resizeMode={'cover'}
              source={{ uri: imgSrc }}
            ></Image>
            <View style={styles.headText}>
              <Text style={styles.title}>
                Ismerd meg a nyelvválasztó funkciót!
              </Text>
            </View>
          </View>
          <View style={styles.body}>
            <Image
              style={styles.banner}
              containerStyle={styles.bannerContainer}
              placeholderStyle={styles.bannerPlaceholder}
              source={{ uri: bannerSrc }}
              resizeMode={'contain'}
            ></Image>
            <Text style={styles.bodyText}>
              Mától a kártyaképet is lefordíthatod.
            </Text>
            <Text style={styles.bodyText}>
              Kattints a legördülő zászló ikonra.
            </Text>
          </View>

          <TouchableOpacity
            style={styles.button}
            activeOpacity={0.5}
            onPress={() => {
              setIsVisible(false)
              localStorage.setItem(languageFeatureModalPath, 'hidden')
            }}
          >
            <Text style={styles.textStyle}>Megértettem</Text>
          </TouchableOpacity>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'gray',
    width: '100%',
    height: '100%',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    width: '100%',
    backgroundColor: Colors.transparentBackgroundOxfordBlue,
    overflow: 'scroll',
  },
  modalView: {
    padding: 20,
    backgroundColor: 'white',
    borderRadius: 10,
    borderWidth: 0,
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    maxWidth: '75vw',
  },
  text: {
    alignContent: 'center',
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 15,
    marginBottom: 10,
  },
  highlight: {
    fontFamily: 'Roboto_500Medium',
  },
  buttonView: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  buttonTextContinue: {
    color: Colors.white,
    fontFamily: 'Roboto_400Regular',
    fontSize: 13,
  },
  buttonContinue: {
    marginLeft: 10,
    padding: 5,
    paddingHorizontal: 15,
    width: 'fit-content',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.oxfordBlue,
    borderRadius: 5,
    outlineStyle: 'none',
  },
  button: {
    backgroundColor: Colors.orangeWeb,
    borderRadius: 10,
    padding: 10,
    elevation: 2,
  },
  textStyle: {
    color: 'white',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  head: {
    marginTop: 0,
    marginBottom: 24,
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  imagePlaceholder: { backgroundColor: 'transparent' },
  imageContainer: {
    backgroundColor: 'gray',
    borderRadius: 10,
  },
  image: { width: 56, height: 56 },
  bannerContainer: {
    width: '100%',
    height: '33.3vh',
    backgroundColor: '#e6e8eb',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  bannerPlaceholder: {
    backgroundColor: 'transparent',
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
  },
  banner: {
    width: '100%',
    height: '100%',
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  headText: {
    marginLeft: 10,
    flex: 1,
    textAlign: 'center',
    paddingVertical: 10,
    fontSize: 24,
  },
  title: { fontWeight: 'bold', fontSize: 24 },
  subtitle: { color: 'gray', fontSize: 16 },
  body: {
    marginBottom: 25,
    paddingTop: 10,
  },
  bodyText: {
    paddingVertical: 10,
    textAlign: 'center',
    fontSize: 18,
  },
})
