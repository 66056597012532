import * as React from 'react'
import { FunctionComponent } from 'react'
import { View, StyleSheet } from 'react-native'
import { ITag } from '../api/tags'
import Colors from '../constants/Colors'

export interface ISentimentIconProps {
  sentimentTag: ITag
  isInternalArticle: boolean
}

enum Sentiment {
  bad = 'bad',
  neutral = 'neutral',
  good = 'good',
}

const styles = StyleSheet.create({
  square: {
    justifyContent: 'center',
    marginHorizontal: 1,
    alignSelf: 'center',
  },
  bad: {
    backgroundColor: 'red',
  },
  neutral: {
    backgroundColor: Colors.orangeWeb,
  },
  good: {
    backgroundColor: 'green',
  },
  active: { width: 10, height: 10, borderRadius: 2 },
  inactive: {
    width: 6,
    height: 6,
    backgroundColor: Colors.darkPlatinum,
    borderRadius: 1,
  },
})

export const SentimentIcon: FunctionComponent<ISentimentIconProps> = ({
  sentimentTag,
  isInternalArticle,
}) => {
  return (
    <View
      style={[
        {
          flexDirection: 'row',
          justifyContent: 'center',
          marginVertical: 2,
        },
      ]}
    >
      <View
        style={[
          styles.square,
          Sentiment[sentimentTag.Value as Sentiment] === Sentiment.bad
            ? [styles.bad, styles.active]
            : styles.inactive,
        ]}
      ></View>
      <View
        style={[
          styles.square,
          Sentiment[sentimentTag.Value as Sentiment] === Sentiment.neutral
            ? [styles.neutral, styles.active]
            : styles.inactive,
        ]}
      ></View>
      <View
        style={[
          styles.square,
          Sentiment[sentimentTag.Value as Sentiment] === Sentiment.good
            ? [styles.good, styles.active]
            : styles.inactive,
        ]}
      ></View>
    </View>
  )
}
