import { IArticle } from '../../api/articles'

export interface IArticlesState {
  isLoading: boolean
  total: number
  articles: Array<IArticle>
  error?: Error
}

export const initialArticleState: IArticlesState = {
  isLoading: false,
  total: 0,
  articles: [],
}
