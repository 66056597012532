import React, { useEffect, useRef, useState, FunctionComponent } from 'react'
import {
  Text,
  StyleSheet,
  Pressable,
  ScrollView,
  View,
  Dimensions,
} from 'react-native'
import Colors from '../constants/Colors'

export interface IAnalysisTopTabNavigationBarProps {
  state: any
  descriptors: any
  navigation: any
}

const styles = StyleSheet.create({
  text: {
    width: 'max-content',
    alignSelf: 'center',
    position: 'relative',
    fontSize: 15,
    fontDisplay: 'block',
    marginVertical: 15,
  },
  pressable: { justifyContent: 'center' },
  hidden: {
    height: 0,
    maxHeight: 0,
    backgroundColor: Colors.white,
  },
  block: {
    height: 50,
    maxHeight: 50,
    backgroundColor: Colors.white,
  },
})

export const AnalysisTopTabNavigationBar: FunctionComponent<
  IAnalysisTopTabNavigationBarProps
> = (props) => {
  const { state, descriptors, navigation } = props
  const scrollView = useRef<ScrollView>(null)
  const widthRefFirst = useRef<any>(null)
  const widthRefLast = useRef<any>(null)
  const [navPosition, setNavPosition] = useState(0)
  const [isFocused, setIsFocused] = useState(0)
  let scrollPosition = 0

  useEffect(() => {
    scrollView.current?.scrollTo({ x: navPosition })
  }, [navPosition])

  useEffect(() => {
    if (scrollView.current && isFocused !== state.index) {
      const parent = (scrollView.current as any).childNodes[0]
      const child = parent.children[state.index]
      const parentRect = parent.getBoundingClientRect()
      const childRect = child.getBoundingClientRect()
      const screenWidth = Dimensions.get('window').width
      const navPosition =
        childRect.left - parentRect.left - screenWidth / 2 + childRect.width / 2
      setNavPosition(navPosition)
      setIsFocused(state.index)
    }
  }, [state.index])

  return (
    <View style={state.routes.length <= 1 ? styles.hidden : styles.block}>
      <ScrollView
        ref={scrollView}
        horizontal
        showsHorizontalScrollIndicator={false}
        decelerationRate={0}
        onScroll={(event) => {
          scrollPosition = event.nativeEvent.contentOffset.x
        }}
        scrollEventThrottle={0}
        removeClippedSubviews
      >
        {state.routes.map(
          (route: { key: string | number; name: any }, index: any) => {
            const { options } = descriptors[route.key]
            const label =
              options.tabBarLabel !== undefined
                ? options.tabBarLabel
                : options.title !== undefined
                ? options.title
                : route.name

            const isFocused = state.index === index

            const onPress = (event: any) => {
              const navigationEvent = navigation.emit({
                type: 'tabPress',
                target: route.key,
                canPreventDefault: true,
              })
              const { x, width } = event.target.getBoundingClientRect()
              const screenWidth = Dimensions.get('window').width

              setNavPosition(
                scrollPosition - (screenWidth / 2 - (x + width / 2))
              )
              setIsFocused(index)
              if (!isFocused && !navigationEvent.defaultPrevented) {
                navigation.navigate({ name: route.name, merge: true })
              }
            }

            return (
              <Pressable
                accessibilityRole="button"
                accessibilityState={isFocused ? { selected: true } : {}}
                accessibilityLabel={options.tabBarAccessibilityLabel}
                testID={options.tabBarTestID}
                onPress={onPress}
                style={[
                  styles.pressable,
                  {
                    marginLeft:
                      index === 0
                        ? Dimensions.get('window').width / 2 -
                          widthRefFirst.current?.clientWidth / 2
                        : 10,
                    marginRight:
                      index === state.routes.length - 1
                        ? Dimensions.get('window').width / 2 -
                          widthRefLast.current?.clientWidth / 2
                        : 10,
                  },
                ]}
                key={`Analysis${route.key}`}
              >
                <Text
                  ref={
                    index === 0
                      ? widthRefFirst
                      : index === state.routes.length - 1
                      ? widthRefLast
                      : null
                  }
                  style={[
                    styles.text,
                    {
                      color: isFocused
                        ? Colors.oxfordBlue
                        : Colors.unselectedBlue,
                      fontFamily: isFocused
                        ? 'Roboto_500Medium'
                        : 'Roboto_300Light',
                    },
                  ]}
                >
                  {label.toUpperCase()}
                </Text>
              </Pressable>
            )
          }
        )}
      </ScrollView>
    </View>
  )
}
