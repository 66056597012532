import ReactGA from 'react-ga'
import { googleAppId } from './Config'

export class GoogleTracker {
  private static interval = 5000
  private timer?: ReturnType<typeof setTimeout>

  constructor() {
    ReactGA.initialize(googleAppId)
  }

  public start(): void {
    ReactGA.pageview(window.location.pathname)
    this.timer = setTimeout(() => this.start(), GoogleTracker.interval)
  }

  public stop(): void {
    if (!this.timer) return
    clearTimeout(this.timer)
    delete this.timer
  }
}
