import React, { FC } from 'react'
import { ContentTagsConsumer, UserConsumer } from '.'
import { ITag } from '../api/tags'

export const PreferredTagsContext = React.createContext<Array<ITag>>([])

export interface IPreferredTagsProps {
  children?: JSX.Element
}

export const PreferredTagsProvider: FC<IPreferredTagsProps> = (props) => {
  return (
    <UserConsumer>
      {({ user }) => (
        <ContentTagsConsumer>
          {({ tags }) => (
            <PreferredTagsContext.Provider
              value={tags.filter((tag) =>
                user.PreferredTags.includes(tag.Value)
              )}
            >
              {props.children}
            </PreferredTagsContext.Provider>
          )}
        </ContentTagsConsumer>
      )}
    </UserConsumer>
  )
}

export const PreferredTagsConsumer = PreferredTagsContext.Consumer
