import React, { FunctionComponent, useEffect, useState } from 'react'
import { View, ScrollView, StyleSheet } from 'react-native'
import RenderHTML from 'react-native-render-html'
import decodeHtml from '../utils/HtmlDecoder'
import { GetTerms } from '../api/user/user.query'
import Colors from '../constants/Colors'

export interface ITermsOfServiceProps {
  token: string
}

export const TermsOfServiceComponent: FunctionComponent<
  ITermsOfServiceProps
> = (props) => {
  const { token } = props
  const [getTerms, setTerms] = useState<string>('')

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const response = await GetTerms({ RegistrationToken: token ?? '' })
        setTerms(response?.Terms ?? '')
      } catch (error) {
        console.error(error)
      }
    }

    fetchArticle()
  }, [token])

  return (
    <View style={styles.container}>
      <View style={styles.mainBody}>
        <ScrollView
          keyboardShouldPersistTaps="handled"
          showsVerticalScrollIndicator={false}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={{
            flex: 1,
            marginTop: 0,
            alignContent: 'center',
            justifyContent: 'center',
          }}
        >
          <RenderHTML
            contentWidth={100}
            source={{ html: decodeHtml(getTerms) }}
          />
        </ScrollView>
      </View>
    </View>
  )
}

export default TermsOfServiceComponent

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: Colors.listWhite,
  },
  mainBody: {
    flex: 1,
    justifyContent: 'center',
    alignContent: 'center',
    backgroundColor: Colors.white,
    maxWidth: 700,
    height: '100%',
    width: '100%',
    marginRight: 'auto',
    marginLeft: 'auto',
    paddingRight: 20,
    paddingLeft: 20,
  },
  termsContent: {
    flex: 1,
  },
})
