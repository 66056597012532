import { LanguageActions } from './languages.actions'
import { ILanguagesState, initialLanguageState } from './languages.state'

export const languagesReducer = (
  state: ILanguagesState,
  action: LanguageActions
): ILanguagesState => {
  switch (action.type) {
    case 'LANGUAGES_LOAD_SUCCESS':
      return {
        ...state,
        languages: action.payload,
      }
    case 'LANGUAGES_LOAD_ERROR':
      return {
        ...state,
        error: action.error,
      }
    case 'LANGUAGES_SET_LOADING':
      return { ...state, isLoading: action.payload }
    case 'LANGUAGES_RESET_STATE':
      return { ...initialLanguageState }
    default:
      return state
  }
}
