import React, { useEffect, useReducer } from 'react'
import { IUserReadMessage } from '../api/user'
import { ILoginParams } from '../api/user/user.query'
import { authTokenPath } from '../constants/Config'
import { initialUserState, IUserState, userReducer } from '../state'
import {
  useLogin,
  useLogout,
  useSetPreferredTags,
  useSetBookmarks,
  useFetchProfile,
  useSetPreferredLanguage,
  useSetSnoozeTime,
  useSetDidUnsubscribe,
  useFetchProfileUnreadMessages,
  useSetReadMessages,
  useSetMarkAllRead,
} from '../state/user/user.creators'

export interface IUserContext extends IUserState {
  login: (params: ILoginParams) => Promise<void>
  logout: () => Promise<void>
  fetchProfile: () => Promise<void>
  setPreferredTags: (tags: Array<string>) => Promise<void>
  setPreferredLanguage: (prefLanguage: string) => Promise<void>
  setSnoozeTime: (snoozeTime: string) => Promise<void>
  setDidUnsubscribe: (didUnsubscribe: boolean) => Promise<void>
  setBookmarks: (
    articleIDs: Array<string>,
    analysesIDs: Array<string>
  ) => Promise<void>
  fetchProfileUnreadMessages: () => Promise<void>
  setReadMessages: (
    contentIds: string[],
    params: IUserReadMessage
  ) => Promise<void>
  setMarkAllRead: () => Promise<void>
}

export const UserContext = React.createContext<IUserContext>({
  ...initialUserState,
  login: async () => {},
  logout: async () => {},
  fetchProfile: async () => {},
  setPreferredTags: async () => {},
  setPreferredLanguage: async () => {},
  setSnoozeTime: async () => {},
  setDidUnsubscribe: async () => {},
  setBookmarks: async () => {},
  fetchProfileUnreadMessages: async () => {},
  setReadMessages: async () => {},
  setMarkAllRead: async () => {},
})

export interface IUserProviderProps {
  children?: JSX.Element
}

export const UserProvider = (props: IUserProviderProps) => {
  const [data, dispatch] = useReducer(userReducer, initialUserState)
  const fetchProfile = useFetchProfile(dispatch)
  const fetchProfileUnreadMessages = useFetchProfileUnreadMessages(dispatch)
  const setReadMessages = useSetReadMessages(dispatch)
  const setMarkAllRead = useSetMarkAllRead(dispatch)
  const login = useLogin(dispatch)
  const logout = useLogout(dispatch)
  const setPreferredTags = useSetPreferredTags(dispatch)
  const setPreferredLanguage = useSetPreferredLanguage(dispatch)
  const setSnoozeTime = useSetSnoozeTime(dispatch)
  const setDidUnsubscribe = useSetDidUnsubscribe(dispatch)
  const setBookmarks = useSetBookmarks(dispatch)

  useEffect(() => {
    if (!localStorage.getItem(authTokenPath)) return
    fetchProfile()
    fetchProfileUnreadMessages()
  }, [])

  return (
    <UserContext.Provider
      value={{
        ...data,
        login,
        logout,
        fetchProfile,
        setPreferredTags,
        setPreferredLanguage,
        setSnoozeTime,
        setDidUnsubscribe,
        setBookmarks,
        fetchProfileUnreadMessages,
        setReadMessages,
        setMarkAllRead,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}

export const UserConsumer = UserContext.Consumer
