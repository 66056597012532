import { IAnalysis } from '../../api/analyses'
import { ITag } from '../../api/tags'

export interface IAnalysesState {
  isLoading: boolean
  total: number
  analyses: Array<IAnalysis>
  error?: Error
}

export const initialAnalysesState: IAnalysesState = {
  isLoading: false,
  total: 0,
  analyses: [],
}
