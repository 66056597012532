export function reorderList(data: any, sourceLanguage?: string) {
  // First, find the index of the item where the Code is an empty string
  // and the Name is 'Eredeti'
  const emptyCodeIndex = data.findIndex(
    (item: { Code: string; Name: string }) =>
      item.Code === '' && item.Name === 'Eredeti'
  )

  // If found, remove the item with an empty Code and insert it at index 0
  if (emptyCodeIndex > -1) {
    const [itemWithEmptyCode] = data.splice(emptyCodeIndex, 1)
    data.splice(0, 0, itemWithEmptyCode)
  }

  // If sourceLanguage is provided, perform the additional sorting based on the source language
  if (sourceLanguage) {
    // Find the index of the item where Code matches the sourceLanguage
    const matchingIndex = data.findIndex(
      (item: { Code: string }) => item.Code === sourceLanguage
    )

    // If found, remove the matching item and insert it at index 1
    // Index 1 is used assuming the first step might have already inserted an item at index 0
    if (matchingIndex > -1) {
      const [matchingItem] = data.splice(matchingIndex, 1)
      data.splice(1, 0, matchingItem)
    }
  }

  // Return the reordered data
  return data
}
