import React, { FC, useContext } from 'react'
import { useState } from 'react'
import { StyleSheet, View } from 'react-native'
import { ArticleTabParamProps } from '../types'
import Colors from '../constants/Colors'
import { UserContext } from '../context'
import { InternalArticleComponent } from '../components/InternalArticleComponent'

export const InternalArticleScreen: FC<ArticleTabParamProps<'ArticleList'>> = (
  props
) => {
  const [isPDFLoading, setPDFLoading] = useState(true)
  const internalArticle = props.route.params
  const { user, setBookmarks } = useContext(UserContext)
  const index = user.BookmarkedNews.indexOf(internalArticle.ArticleId || '')
  const isBookmarked = index > -1

  return (
    <View style={styles.container}>
      <InternalArticleComponent
        articleId={internalArticle.ArticleId ?? ''}
        navigation={props.navigation}
        onLanguageChange={(language) => {}}
        isPublicArticle={false}
      ></InternalArticleComponent>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: Colors.listWhite,
    display: 'flex',
    alignItems: 'center',
  },
})
