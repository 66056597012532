import * as React from 'react'
import { View, StyleSheet } from 'react-native'

import { ArticleTabParamProps } from '../types'
import { FiltersConsumer } from '../context'
import { ListComponent } from '../components/ListComponent'
import Colors from '../constants/Colors'

const styles = StyleSheet.create({
  view: {
    flex: 1,
    backgroundColor: Colors.white,
  },
})
export const ListScreen: React.FC<ArticleTabParamProps<'ArticleList'>> = ({
  navigation,
}) => {
  return (
    <View style={styles.view}>
      <FiltersConsumer>
        {({ SourceTag, ContentTag }) => {
          return (
            <ListComponent
              navigation={navigation}
              SourceTag={SourceTag}
              ContentTag={ContentTag}
            />
          )
        }}
      </FiltersConsumer>
    </View>
  )
}
