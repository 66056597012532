import React, { FC, useContext, useEffect, useState } from 'react'
import { View, StyleSheet, Text } from 'react-native'
import Colors from '../constants/Colors'
import { UserContext } from '../context'
import { RootStackScreenProps } from '../types'
import { Spinner } from '../components/Spinner'
import { ScreenSizeConsumer } from '../context/screen-size.context'
import { GetContacts } from '../api/user/user.query'
import { IContactDto } from '../api/user'

export const ConnectionScreen: FC<RootStackScreenProps<'Connection'>> = () => {
  const { isLoading } = useContext(UserContext)

  const [contacts, setContacts] = useState<IContactDto>()

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const fetchedContacts = await GetContacts()

        setContacts(fetchedContacts?.Contacts)
      } catch (error) {
        console.error('Failed to fetch contacts', error)
      }
    }
    fetchContacts()
  }, [])

  return (
    <ScreenSizeConsumer>
      {({ height }) => (
        <View style={[styles.containerView, { height: height - 50 }]}>
          <View style={[styles.view, styles.viewSplit]}>
            <Text style={styles.text}>
              Kérdés esetén az alábbi elérhetőségeken állunk rendelkezésedre!
            </Text>
          </View>

          <View style={[styles.view, styles.viewSplit]}>
            <Text style={styles.text}>Email</Text>
            <Text style={styles.text}>{contacts?.Email}</Text>
          </View>
          <View style={styles.view}>
            <Text style={styles.text}>Telefonszám</Text>
            <Text style={styles.text}>{contacts?.Phone}</Text>
          </View>
          <Spinner isLoading={isLoading}></Spinner>
        </View>
      )}
    </ScreenSizeConsumer>
  )
}

const styles = StyleSheet.create({
  containerView: {
    backgroundColor: Colors.white,
  },
  view: {
    height: 50,
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginBottom: '0px',
  },
  viewSplit: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.platinum,
  },
  text: {
    color: Colors.oxfordBlue,
    fontFamily: 'Roboto_400Regular',
    fontSize: 15,
    marginHorizontal: 13,
  },
})
