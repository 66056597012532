import { ITag } from '../../api/tags'

export interface ITagsState {
  isLoading: boolean
  tags: Array<ITag>
}

export const initialTagsState: ITagsState = {
  isLoading: false,
  tags: [],
}
