import React, { FunctionComponent } from 'react'
import { createMaterialTopTabNavigator } from '@react-navigation/material-top-tabs'
import Colors from '../constants/Colors'
import { AnalysisTabParamList, AnalysisTabParamProps } from '../types'
import { FiltersProvider } from '../context'
import { AnalysisTopTabNavigationBar } from '../components/AnaysisTopTabNavigationBar'
import { PreferredTagsConsumer } from '../context/preferred-tags.context'
import { MaterialTopTabBarProps } from '@react-navigation/material-top-tabs/lib/typescript/src/types'
import { ScrollToTopProvider } from '../context/scroll-to-top.context'

const AnalysisTopTab = createMaterialTopTabNavigator<AnalysisTabParamList>()

export const AnalysisTopTabNavigator: FunctionComponent<
  AnalysisTabParamProps<'AnalysisList'>
> = ({ route, children }) => {
  const { ScrollToTop } = route.params

  return (
    <ScrollToTopProvider ScrollToTop={ScrollToTop}>
      <PreferredTagsConsumer>
        {(tags) => {
          const filteredTags = tags.filter((tag) => tag.HasAnalyses)
          return (
            <AnalysisTopTab.Navigator
              tabBar={(
                props: JSX.IntrinsicAttributes & MaterialTopTabBarProps
              ) => <AnalysisTopTabNavigationBar {...props} />}
              screenOptions={{
                tabBarActiveTintColor: Colors.oxfordBlue,
                tabBarScrollEnabled: true,
              }}
            >
              <AnalysisTopTab.Screen
                name="AnalysisList"
                options={{
                  title: 'Minden Téma',
                }}
              >
                {() => <FiltersProvider>{children as any}</FiltersProvider>}
              </AnalysisTopTab.Screen>
              {filteredTags.map((tag) => (
                <AnalysisTopTab.Screen
                  key={`Analysis${tag.Value}`}
                  name={`Analysis${tag.Value}List` as any}
                  options={{
                    title: tag.Title,
                  }}
                >
                  {() => (
                    <FiltersProvider ContentTag={tag.Value}>
                      {children as any}
                    </FiltersProvider>
                  )}
                </AnalysisTopTab.Screen>
              ))}
            </AnalysisTopTab.Navigator>
          )
        }}
      </PreferredTagsConsumer>
    </ScrollToTopProvider>
  )
}
