import {
  IUserQuery,
  ILoginMutation,
  ILogoutMutation,
  IUserUnreadMessagesQuery,
  IMarkAllReadMutation,
  IUserReadMessage,
  IContactDto,
  IToS,
  ITermsParam,
} from './user.types'
import { client } from '..'
import Browser from '../../constants/Browser'
import { deviceIdPath, deviceNamePath } from '../../constants/Config'
import { omitEmpty } from '../../utils'

export async function fetchProfile() {
  try {
    const deviceId = localStorage.getItem(deviceIdPath)
    const deviceName = localStorage.getItem(deviceNamePath)
    const response = await client.get<IUserQuery>('/api/profile', {
      params: omitEmpty({
        deviceId,
        deviceName,
      }),
    })

    return {
      ...response.data,
      deviceId,
      deviceName,
      PreferredTags: (response.data.PreferredTags || []).filter((t) => !!t),
      BookmarkedNews: (response.data.BookmarkedNews || []).filter((t) => !!t),
      BookmarkedAnalyses: (response.data.BookmarkedAnalyses || []).filter(
        (t) => !!t
      ),
    }
  } catch (error) {
    console.error(error)
  }
}

export async function fetchProfileUnreadMessages() {
  try {
    const response = await client.get<IUserUnreadMessagesQuery>(
      '/api/profile/unread-messages'
    )
    return {
      ...response.data,
      unreadArticlesCount: response.data.unreadArticlesCount || 0,
      unreadAnalysesCount: response.data.unreadAnalysesCount || 0,
      unreadArticles: response.data.unreadArticles || [],
      unreadAnalyses: response.data.unreadAnalyses || [],
    }
  } catch (error) {
    console.error(error)
  }
}

export async function updateReadMessages(
  contentIds: string[],
  params: IUserReadMessage
) {
  try {
    await client.put<IUserUnreadMessagesQuery>('/api/profile/read-messages', {
      ...params,
      contentIds,
    })
  } catch (error) {
    console.error(error)
  }
}

export async function markAllRead() {
  const response = await client.patch<IMarkAllReadMutation>(
    '/api/profile/mark-all-read'
  )
  if (response.data?.Error) throw new Error(response.data.Error)
  if (response.data?.error) throw new Error(response.data.error)
  return response.data
}

export interface ILoginParams {
  Email: string
  ActivationCode: string
}

export interface IRegistrationParams {
  Name: string
  Email: string
  PhoneNumber: string
  Token: string | null
}

export interface IRegistrationTokenParams {
  Token: string
}

export async function login(params: ILoginParams) {
  const deviceId = localStorage.getItem(deviceIdPath)
  const deviceName = localStorage.getItem(deviceNamePath)
  const response = await client.post<ILoginMutation>('/api/login', {
    username: params.Email,
    password: params.ActivationCode,
    isIOS: `${Browser.isIOS}`,
    deviceName,
    deviceId,
  })
  if (response.data?.Error) throw new Error(response.data.Error)
  if (response.data?.error) throw new Error(response.data.error)
  return response.data
}

export async function registration(params: IRegistrationParams) {
  const deviceId = localStorage.getItem(deviceIdPath)
  const deviceName = localStorage.getItem(deviceNamePath)
  const response = await client.post<ILoginMutation>(
    '/api/member/registration',
    {
      name: params.Name,
      email: params.Email,
      phoneNumber: params.PhoneNumber,
      token: params.Token,
    }
  )

  if (response.data?.Error) throw new Error(response.data.Error)
  if (response.data?.error) throw new Error(response.data.error)
  return response.data
}

export async function checkRegistrationToken(token: string | null) {
  if (token === null) {
    return
  }
  const response = await client.get('/api/member/check-token', {
    params: omitEmpty({
      token,
    }),
  })
  return response.data
}

export async function refreshToken(refreshtoken: string) {
  const response = await client.post<ILoginMutation>('/api/refreshtoken', {
    refreshtoken,
  })
  return response.data
}

export async function logout() {
  const deviceId = localStorage.getItem(deviceIdPath)
  const response = await client.post<ILogoutMutation>('/api/logout', {
    deviceId,
  })
  if (response.data?.Error) throw new Error(response.data.Error)
  if (response.data?.error) throw new Error(response.data.error)
  return response.data
}

export interface IUpdateProfileParams {
  PreferredTags?: Array<string>
  PreferredLanguage?: string
  BookmarkedNews?: Array<string>
  BookmarkedAnalyses?: Array<string>
}

export async function updateProfile(params: IUpdateProfileParams) {
  const deviceId = localStorage.getItem(deviceIdPath)
  const deviceName = localStorage.getItem(deviceNamePath)
  return client.put<ILoginMutation>('/api/profile', {
    ...params,
    deviceId,
    deviceName,
  })
}

export interface IUpdateSnoozeParams {
  SnoozeTime?: string
}

export async function updateSnooze(params: IUpdateSnoozeParams) {
  try {
    await client.put<ILoginMutation>('/api/snooze', params)
  } catch (error) {
    console.error(error)
  }
}

export interface IUpdateUnsubscribeParams {
  DidUnsubscribe?: boolean
}

export async function updateUnsubscribe(params: IUpdateUnsubscribeParams) {
  try {
    await client.post<ILoginMutation>('/api/unsubscribe', params)
  } catch (error) {
    console.error(error)
  }
}

export async function GetContacts() {
  try {
    const response = await client.get<{ Contacts: IContactDto }>(
      '/api/profile/contacts'
    )

    return response.data
  } catch (error) {
    console.error(error)
  }
}

export async function GetTerms(params: ITermsParam) {
  const { RegistrationToken } = params
  try {
    const response = await client.get<IToS>('/api/member/terms', {
      params: omitEmpty({
        RegistrationToken,
      }),
    })
    return response.data
  } catch (error) {
    console.error(error)
  }
}
