import React, { useState, ReactNode, useRef } from 'react'
import { View, Text, StyleSheet, LayoutChangeEvent } from 'react-native'

interface TooltipProps {
  text: string
  visible: boolean
  children: ReactNode
}

const CustomTooltip: React.FC<TooltipProps> = ({ text, visible, children }) => {
  const [tooltipWidth, setTooltipWidth] = useState(0)
  const tooltipRef = useRef<View>(null)

  const onLayout = (event: LayoutChangeEvent) => {
    const { width } = event.nativeEvent.layout
    setTooltipWidth(width)
  }

  return (
    <View style={styles.container}>
      <View>{children}</View>
      {visible && (
        <View
          style={[styles.tooltip, { marginLeft: -tooltipWidth / 2 }]}
          onLayout={onLayout}
          ref={tooltipRef}
        >
          <Text style={styles.tooltipText}>{text}</Text>
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: 'relative',
    alignItems: 'center',
  },
  tooltip: {
    position: 'absolute',
    bottom: '75%',
    left: '50%',
    marginBottom: 5,
    padding: 6,
    backgroundColor: 'rgba(97, 97, 97, 0.90)',
    borderRadius: 90,
    zIndex: 1,
  },
  tooltipText: {
    color: 'white',
    fontSize: 11,
  },
})

export default CustomTooltip
