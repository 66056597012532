import React, { useEffect, useReducer } from 'react'
import { initialTagsState, ITagsState, tagReducer } from '../state'
import { useFetchTags } from '../state/tags/tags.creators'

export const ContentTagsContext =
  React.createContext<ITagsState>(initialTagsState)

export interface IContentTagsProps {
  children?: JSX.Element
  token?: string
}

export const ContentTagsProvider = (props: IContentTagsProps) => {
  const { token } = props
  const [data, dispatch] = useReducer(tagReducer, initialTagsState)
  const fetchTags = useFetchTags(dispatch)
  useEffect(() => {
    if (!token) return
    fetchTags({ TagType: 'content' })
  }, [token])

  return (
    <ContentTagsContext.Provider value={data}>
      {props.children}
    </ContentTagsContext.Provider>
  )
}

export const ContentTagsConsumer = ContentTagsContext.Consumer
