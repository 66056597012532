import React, { FunctionComponent } from 'react'
import { View, StyleSheet, Pressable } from 'react-native'
import Icon from 'react-native-vector-icons/MaterialIcons'
import Colors from '../constants/Colors'

export interface IBottomNavigationBarProps {
  state: any
  descriptors: any
  navigation: any
}

const styles = StyleSheet.create({
  bottomNavigationBar: {
    flexDirection: 'row',
    marginBottom: 12,
    padding: 5,
    height: 50,
    width: 150,
    alignSelf: 'center',
    backgroundColor: Colors.transparentOxfordBlue,
    borderRadius: 200,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: 0,
  },
  icon: {
    padding: 1,
    borderBottomWidth: 2,
  },
  iconWrap: {
    marginHorizontal: 10,
  },
})

const checkWithRootSearchFavorites = (item: string) =>
  item === 'Root' ||
  item === 'Search' ||
  item === 'Favorites' ||
  item === 'AnalysisRoot' ||
  item === 'AnalysisSearch' ||
  item === 'AnalysisFavorites'

export const BottomNavigationBar: FunctionComponent<
  IBottomNavigationBarProps
> = (props) => {
  const { state, descriptors, navigation } = props
  return checkWithRootSearchFavorites(state.routes[state.index].name) ? (
    <View style={styles.bottomNavigationBar}>
      {state.routes.map((route: any, index: any) => {
        const { options } = descriptors[route.key]
        const isFocused = state.index === index

        const onPress = () => {
          const event = navigation.emit({
            type: 'tabPress',
            target: route.key,
            canPreventDefault: true,
          })

          if (!isFocused && !event.defaultPrevented) {
            navigation.navigate({ name: route.name, merge: true })
          } else if (
            isFocused &&
            (route.name === 'Root' || route.name === 'AnalysisRoot')
          ) {
            navigation.setParams({
              ScrollToTop: true,
            })
          }
        }

        return (
          checkWithRootSearchFavorites(route.name) && (
            <Pressable
              accessibilityRole="button"
              accessibilityState={isFocused ? { selected: true } : {}}
              accessibilityLabel={options.tabBarAccessibilityLabel}
              testID={options.tabBarTestID}
              onPress={onPress}
              style={styles.iconWrap}
              key={route.key}
            >
              <View
                style={[
                  styles.icon,
                  {
                    borderBottomColor: isFocused
                      ? Colors.orangeWeb
                      : '#ffffff00',
                  },
                ]}
              >
                <Icon
                  name={
                    route.name === 'Root' || route.name === 'AnalysisRoot'
                      ? 'roofing'
                      : route.name === 'Search' ||
                        route.name === 'AnalysisSearch'
                      ? 'search'
                      : 'bookmark-border'
                  }
                  size={24}
                  color={Colors.orangeWeb}
                />
              </View>
            </Pressable>
          )
        )
      })}
    </View>
  ) : (
    <></>
  )
}
