import { difference, uniq } from 'lodash'
import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { UserContext } from './user.context'

const emptyMessageCache: IMessageCache = {
  unreadArticles: [],
  unreadAnalyses: [],
}

export const UnreadContext = React.createContext<IUnread>({
  unreadItems: emptyMessageCache,
  setUnreadItems: () => {},
  refresh: async () => {},
})

export interface IUnread {
  unreadItems: IMessageCache
  setUnreadItems: Dispatch<SetStateAction<IMessageCache>>
  refresh: () => Promise<void>
}

export interface IMessageCache {
  unreadArticles: string[]
  unreadAnalyses: string[]
}
export const UnreadProvider: FC<PropsWithChildren<unknown>> = (props) => {
  const { userUnreadMessages, fetchProfileUnreadMessages, setReadMessages } =
    useContext(UserContext)

  const [unreadItems, setUnreadItems] =
    useState<IMessageCache>(userUnreadMessages)
  const [prevUnread, setPrevUnread] = useState<IMessageCache>(unreadItems)
  const [readItems, setReadItems] = useState<IMessageCache>(emptyMessageCache)

  const readMarked = useCallback(async () => {
    const promises = []
    if (readItems.unreadArticles.length)
      promises.push(
        setReadMessages(readItems.unreadArticles, { ContentType: 'Article' })
      )
    if (readItems.unreadAnalyses.length)
      promises.push(
        setReadMessages(readItems.unreadAnalyses, { ContentType: 'Analysis' })
      )

    await Promise.all(promises)
    setReadItems(emptyMessageCache)
  }, [readItems, setReadMessages, setReadItems])

  const refresh = useCallback(async () => {
    await readMarked()
    await fetchProfileUnreadMessages()
  }, [fetchProfileUnreadMessages])

  useEffect(() => {
    const { unreadArticles, unreadAnalyses } = userUnreadMessages
    setUnreadItems({ unreadArticles, unreadAnalyses })
  }, [userUnreadMessages])

  useEffect(() => {
    const diffArticles = difference(
      prevUnread.unreadArticles,
      unreadItems.unreadArticles
    )
    const diffAnalyses = difference(
      prevUnread.unreadAnalyses,
      unreadItems.unreadAnalyses
    )
    if (diffArticles.length || diffAnalyses.length) {
      setReadItems({
        unreadArticles: [...readItems.unreadArticles, ...diffArticles],
        unreadAnalyses: [...readItems.unreadAnalyses, ...diffAnalyses],
      })
    }
    setPrevUnread(unreadItems)
  }, [unreadItems])

  useEffect(() => {
    const timer = setTimeout(async () => {
      await readMarked()
    }, 3000)

    return () => clearTimeout(timer)
  }, [unreadItems, setReadMessages, readMarked])

  const state = useMemo(
    () => ({
      unreadItems,
      setUnreadItems,
      refresh,
    }),
    [unreadItems, setUnreadItems, setReadMessages, fetchProfileUnreadMessages]
  )

  return (
    <UnreadContext.Provider value={state}>
      {props.children}
    </UnreadContext.Provider>
  )
}

export const UnreadConsumer = UnreadContext.Consumer
